import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import Seo from "../components/seo";
import Layout from "../components/layout";

import "../styles/global/base.scss";
import "../styles/recruit.scss";

const RecruitPage = () => {

  return (
    <React.Fragment>
      <Seo title="RECRUIT" pageClass="recruitPage" />
      <Layout>
        <Container fluid className="recruitHeader">
          <Row>
            <Col>
              <h1>RECRUIT</h1>
              <p>ただいま募集は行っておりません。</p>
            </Col>
          </Row>
        </Container>
        {/*
        <Container fluid className="recruitContent">
          <Row>
            <Col md={{ span: 10, offset: 1 }}>
              <h2>募集職種：映像クリエイター</h2>
              <p>
                空間演出および映像コンテンツ制作にまつわる企画からアウトプットまで、<br />
                幅広く能力を活かせる人、活かしたい人を募集します。
              </p>
              <dl>
                <dt>業務内容：</dt>
                <dd>
                  企画立案・ディレクション<br />
                  モデリング　3Dアニメーション　2Dアニメーション<br />
                  VFX　モーショングラフィック　空間パース作成　など
                </dd>
                <dt>応募資格：</dt>
                <dd>
                  実務経験者<br />
                  実務未経験者不可
                </dd>
                <dt>必須スキル：</dt>
                <dd>
                  After Effects<br />
                  Blender（もしくは他のDCCツールでも）
                </dd>
                <dt>優遇スキル：</dt>
                <dd>
                  Unreal Engine / Unityの使用経験<br />
                  TouchDesigner<br />
                  建築/展示などの知識<br />
                  コンテ/イラスト<br />
                  プランニング能力<br />
                  コミュニケーション能力<br />
                  クリエイティブ能力
                </dd>
                <dt>雇用形態：</dt>
                <dd>
                  契約社員（3〜6ヶ月契約）※正社員登用の検討あり
                </dd>
                <dt>給与：</dt>
                <dd>
                  経験・能力を考慮の上、当社規定により決定<br />
                  ※時間外労働45時間までは基本給に含む
                </dd>
                <dt>勤務時間：</dt>
                <dd>
                  10:00〜19:00（休憩1時間）
                </dd>
                <dt>休日：</dt>
                <dd>
                  土・日・祝日<br />
                  ※ただしプロジェクトの状況に応じて休日が変更になる場合があります。<br />
                  ※日曜・祝日勤務の場合は代休制度あり
                </dd>
                <dt>休暇：</dt>
                <dd>
                  夏期休暇　年末年始休暇　慶弔休暇
                </dd>
                <dt>福利厚生：</dt>
                <dd>
                  社会保険各種<br />
                  交通費全額支給
                </dd>
                <dt>勤務地：</dt>
                <dd>
                  東京都世田谷区上北沢4-10-18（京王線上北沢駅／徒歩5分）
                </dd>
                <dt>応募方法：</dt>
                <dd>
                  履歴書・職務経歴書をメールにてお送り下さい。<br />
                  映像クリエーター職へ応募の方は必ず作品URLを添付してください。<br />
                  Mail：<a href="mailto:desk@montage.co.jp">desk@montage.co.jp</a><br />
                  株式会社モンタージュ／採用担当宛
                </dd>
              </dl>
            </Col>
          </Row>
        </Container>
        */}
      </Layout>
    </React.Fragment>
  );
};

export default RecruitPage;
